import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CopyButton,
  Divider,
  MoreButton,
} from '@makeably/creativex-design-system';
import AuditPostCard, { postPropTypes } from 'components/audit/AuditPostCard';
import SafeZoneModal from 'components/organisms/SafeZoneModal';
import VideoPlayer from 'components/organisms/VideoPlayer';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import {
  auditScorecardUrl,
  newAuditChallengePath,
} from 'utilities/routes';
import styles from './CreativeScorecard.module.css';

const propTypes = {
  assetId: PropTypes.number.isRequired,
  assetToChallengeId: PropTypes.number.isRequired,
  canChallengeAsset: PropTypes.bool.isRequired,
  creativeXId: PropTypes.string.isRequired,
  includeMatchedPosts: PropTypes.bool.isRequired,
  isVideo: PropTypes.bool.isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape(postPropTypes)).isRequired,
  sourceUrl: PropTypes.string.isRequired,
  summaryMetrics: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  filterQueryId: PropTypes.number,
  postUrl: PropTypes.string,
  pretestStatus: PropTypes.string,
};

const defaultProps = {
  filterQueryId: null,
  postUrl: null,
  pretestStatus: null,
};

function CreativeScorecard({
  assetId,
  assetToChallengeId,
  canChallengeAsset,
  creativeXId,
  filterQueryId,
  includeMatchedPosts,
  isVideo,
  posts,
  postUrl,
  pretestStatus,
  sourceUrl,
  summaryMetrics,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');
  useViewPage();

  const linkUrl = auditScorecardUrl(assetId, {
    include_matched_posts: includeMatchedPosts,
    pretest_status: pretestStatus,
    filter_query: filterQueryId,
  });

  const challengeUrl = (canChallengeAsset && assetToChallengeId) ? newAuditChallengePath({
    asset_id: assetToChallengeId,
    backlink: linkUrl,
  }) : null;

  const options = [
    {
      label: 'Copy Link',
      onClick: () => {
        navigator.clipboard.writeText(linkUrl);
      },
    },
    {
      label: 'Download',
      url: sourceUrl,
      newTab: true,
    },
  ];

  if (postUrl) {
    options.push({
      label: 'View External Link',
      url: postUrl,
      newTab: true,
    });
  }

  const renderSummaryMetrics = () => (
    summaryMetrics.map(({ label, value }) => (
      <div key={label} className={styles.metric}>
        <div className="t-caption-1">{ label }</div>
        <div className="t-body-1">{ value }</div>
      </div>
    ))
  );

  const renderPosts = () => (
    posts.map((post) => (
      <AuditPostCard
        key={post.id}
        setIsModalOpen={setIsModalOpen}
        setTemplateUrl={setTemplateUrl}
        {...post}
      />
    ))
  );

  return (
    <>
      <SafeZoneModal
        isOpen={isModalOpen}
        source={{
          creativeUrl: sourceUrl,
          isVideo,
        }}
        templateUrl={templateUrl}
        onClose={() => setIsModalOpen(false)}
      />
      <div className={styles.pageContainer}>
        <div className={styles.assetContainer}>
          { isVideo ? (
            <div className={styles.videoContainer}>
              <VideoPlayer
                height="100%"
                source={sourceUrl}
                width="100%"
                hasOptions
              />
            </div>
          ) : (
            <div className={styles.imageContainer}>
              <img
                alt={sourceUrl}
                src={sourceUrl}
              />
            </div>
          ) }
          <Card className={styles.summaryCard}>
            <div className={styles.headerRow}>
              <div>
                <h5>Creative Details</h5>
                <div className="u-flexRow u-alignCenter">
                  { `CreativeX ID: ${creativeXId}` }
                  <CopyButton text={creativeXId} />
                </div>
              </div>
              <div>
                <div className="u-flexRow u-justifyEnd u-alignStretch u-gap-16">
                  <div className={styles.moreButton}>
                    <MoreButton options={options} />
                  </div>
                  <div><Divider vertical /></div>
                  <Button
                    disabled={!challengeUrl}
                    iconRight={challengeUrl ? 'flag' : 'flagFilled'}
                    label={challengeUrl ? 'Flag' : 'Flagged'}
                    url={challengeUrl}
                    variant="secondary"
                    onClick={() => track('click_challenge', { creativeXId })}
                  />
                </div>
              </div>
            </div>
            <div className={styles.summaryMetricGrid}>
              { renderSummaryMetrics() }
            </div>
          </Card>
        </div>
        { renderPosts() }
      </div>
    </>
  );
}

CreativeScorecard.propTypes = propTypes;
CreativeScorecard.defaultProps = defaultProps;

export default CreativeScorecard;
